@import "variables";

.navbar {
  .collapse {
    height: initial !important;
    text-align: right;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }

  &.navbar-light {
    background-color: color(gray, gray_050) !important;
  }


  .navbar-toggler {
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  a {
    color: #212529;
    font-size: 20px;
 }

 .dropdown a {
   padding-right: 13px;
 }
}

.sticky-profile-nav {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 200px;

  &.company {
    background-color: #363636;
  }

  .profile-nav-link {
    height: 3.5em;
    margin: 0;
    padding: .5em 2em 3px 2em;
    line-height: 1;
    font-size: 13px;

    &.single-line {
      line-height: 2;
    }

    span {
      display: block;
    }

    img {
      position: absolute;
      right: 10px;
      top: 17px;
    }
  }
}

.collapse {
  height: 45px !important;

  .collapsing {
    margin-top: -56px;
    height: 300px !important;
  }

  &.show {
    height: auto !important;
  }
}

.nav-login-logout {
  padding-top: 2px;
  color: #57c99f;
  font-size: 16px;
  font-weight: bold;
  font-family: WorkSansSemiBold, sans-serif;
  text-align: right;
  float: right;
  padding-right: 30px;
}

.nav-name {
  color: rgb(32, 32, 32);
  font-family: WorkSansBold, sans-serif;
  img {
    padding-bottom: 2px;
  }
}

.nav-look {
  background: rgb(255, 255, 255);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  height: 80px;
}

.burger-menu-img {
  height: 14px;
  width: 16px;
}

.nav-pad {
  padding-top: 0;
}

/* ====================================
              Media Queries
======================================*/
@media #{$md}, #{$sm} {
  .collapse.show {
    z-index: 2;
    background-color: whitesmoke;

    .navbar-nav {
      padding: .5rem;
      background-color: whitesmoke;
    }
  }
}

@media (min-width: 992px){
  
  .navbar {
    padding: 0px;
  }

  .navbar-expand-lg{
  padding: 0px;
  
   ul{
     height: 80px;
   }
   li{
     padding-top: 17px;
     padding-right: 10px;
     padding-left: 10px;
     &:hover {
       background: #424ce0;
     }
   }

   .nav-white-btn,
   .nav-name{
     &:hover {
       color: white;
      }
    }
   .collapse {
     height: initial;
   }
  }
}

.dropdown-item-checked::before {
  position: absolute;
  right: .6rem;
  content: url(../images/ic_check_icon.svg);
  font-weight: bold;
  color: #3ab084;
}


.w-20 {
  width: 20px
}

.lang-btn {
  background: transparent !important;
  border: transparent !important;
  min-width: 20px !important;
  &:hover {
    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
    color: #fff !important;
    border-color: transparent !important; 
  }

  &:active {
    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
  }

  &:focus {
    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
  }

  &::after {
    color:  #fff !important;
  }
}

.language-nav {

  &:hover {
   background: #424ce0;
  }

  &:active {
   background: #424ce0;
  }

  &::after {
    color: #fff !important;
  }
}


#language-drop-down .dropdown-item {
  width: 100%;

  &:active {
    color: #87d4b8;
    background-color: transparent
  }
  
  &:hover {
    color: #ff7b84;
    background-color: transparent
  }
}

@media #{$sm} {
  .language-nav {

    &:hover {
     background: transparent;
    }

    &:active {
     background: transparent;
    }
  }
  .country-title {
    color: black;
    padding-left: 0.8rem;
  }
}

@media #{$md}, #{$lg}, #{$xl} {
 .country-title{
   display: none
 }
}

.language-switch {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__img {
    height: 24px;
    width: 24px;
    border-radius: 30px;
    z-index: 1;
    margin-left: .5rem;
  }

  &__drop-down {
    z-index: 1;
    padding-top: 15px;
  }

  &__caret {
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0 !important;
    box-shadow: none;

    &:after {
      border-top-color: color(gray, gray_600);
      border-bottom-color: color(gray, gray_600);
      padding-top: 0;
      margin-left: 10px;
    }

    &:focus {
      box-shadow: none;
    }
  }

    &__dropdown-menu {
      padding: 0;
      transform: translate3d(-44px, 55px, 0px) !important;

      &.show {
        left: -70px !important;
        top: -5px !important;
      }
  }

  &__flag-img {
    height: 24px;
    width: 24px;
    margin-left: 0;
    margin-right: 1rem;
    border-radius: 30px;
    border: 1px solid color(gray, gray_300);
  }

  &__dropdown-item {
    padding-bottom: 7px;
    padding-top: 7px;
    border-bottom: 1px solid color(gray, gray_300);

    &:active {
      color: #87d4b8;
      background-color: transparent
    }
  
    &:hover {
      color: #ff7b84;
      background-color: transparent
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

@media #{$sm} {
  .language-switch {
  &__dropdown-menu {
    transform: translate3d(23px, 48px, 0px) !important;
    }
  }
}