@import "variables";
// TODO: media query should be separated
// TODO: create separate .scss file for each component

.investor-kpis {
  .row {
    text-align: left !important;
    margin-bottom: 40px;

    @media #{xl} {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  p {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: normal;

    &.portfolio-title {
      font-size: 65px;
      margin-top: -15px;
      font-weight: 300;
    }
  }
}

.kpi-bottom {
  padding-bottom: 70px !important;
}

.add-funds {
  display: flex;
  align-items: center;
  justify-content: center;

  .row {
    align-items: center;
    justify-content: center;

    span {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1;

      @media #{$small} {
        display: block;
        margin-bottom: 25px;
      }
    }

    img {
      height: 50px;
      margin-left: -25px;

      @media #{$small} {
        display: none;
      }
    }

    p {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
  }
}

.withdraw-funds {

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  .btn {
    height: 60px;
  }

  .icon-stroke {
    display: none;

    @media #{$large} {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: 700;
    }
  }

  @media #{$small} {
    .btn {
      width: 100%;
      border-radius: 10px !important;

      &.withdraw-all {
        margin-top: 20px;
      }
    }
  }

  .withdraw-amount-form {
    position: relative;

    input,
    .btn {
      border-radius: 10px;
      border: 3px solid #000;
      height: 60px;
    }

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.title-placement {
  margin-bottom: 8px;
  color: rgb(185, 220, 253);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.withdraw-successful-done {
  margin-bottom: 80px;
}

.different-padding {
  padding: 0 14px;
}

.value-placement {
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.portfolio-general {
  background-color: color(blue, blue_main);
  line-height: 1.2;

  .container > .row {
    padding: 20px 0 20px;
  }

  .container > .row ~ .row {
    padding: 20px 0 20px;
  }

}

.add-funds__wrapper {
  .add-funds-margin {
    margin: 140px 0;
  }

  .fund-item {
    font-weight: bold;
  }
}

.arrow-to-right {
  margin-right: 50%;
  margin-top: 10px;
  float: right;
  height: 24px;
  width: 24px;
}

.collect-funds {
  color: #FFFFFF;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 45px;
}

.collect-funds-input {
  background: color(gray, gray_800);
  border-radius: 5px;
  height: 48px;
  width: 100%;
  padding: 15px;
  border-width: 0;
  color: white;
}

.collect-all-funds {
  color: color(green, green_main);
  text-align: center;
  margin: 58px 0 78px;
  display: block;
}

.b-collect-funds {
  background: color(gray, gray_900);
  color: color(gray,gray_050);
}

.funds-collected-message {
  color: rgb(255, 255, 255);
  text-align: center;
  margin: 0;
  display: block;
}

.back-color {
  background: color(gray, gray_900);
}

.zero-margin {
  margin-top: 0;
}

.transactions-buttons {
  margin: 0 5px 20px;
  &:hover{
    background: #87d4b8 !important;
    color: #fff !important;
    border-color: transparent !important; 
  }
}

.transactions-header {
  line-height: 48px;
  text-align: center;
  margin: auto;
  margin-top: 87px;
  margin-bottom: 30px;
}

.transactions-background {
  background: color(gray, gray_080);
}

.table-item {
  color: #b0b0b0;
  font-size: 13px;
  font-weight: 500;
  height: 16px;
}

.transactions-align-right {
  text-align: right;
}

.table-rows {
  background: color(gray, gray_100);
  border-radius: 5px;
  height: 40px;
  border-bottom: 2px;
  border-color: color(gray, gray_080) color(gray, gray_100);
  border-style: solid;
}

.transactions-table-row {
  background-color: color(gray, gray_080);
  height: 5px;
}

.load-more {
  color: color(gray, gray_100);
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin: auto;
  margin-top: 50px;
}

.transactions-button-margin {
  margin: 50px auto 80px;
}

.blue-button {
  background: rgb(41, 43, 203) !important;
  border-color: rgb(41, 43, 203) !important;
  color: rgb(58, 176, 132);

  .arrow {
    color: color(blue, blue_main);
  }
}

.version-dropdown-button {
  margin-right: 10px;
}

.dropdown-radius {
  border-radius: 30px !important;
}

.button-placement {
  margin: 30px 0 0;
}

.start-investing {
  text-align: center;
  margin-bottom: 70px;
}

.negative-color {
  color: rgb(255, 123, 132);
}

.positive-color {
  color: #3ab084;
}

.withdrawal-successful {
  margin-top: 80px;
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  background: rgb(66, 66, 66);
  margin-bottom: 60px;
  border-radius: 15px;
  display: inline-block;
  padding: 20px;
}

.img-responsive {
  width: 100%;
}

/* ====================================
              Media Queries
======================================*/
@media #{$sm} {
  .portfolio-general {
    .button-placement {
      margin: auto;
    }
  }
  .add-funds__wrapper {
    .add-funds-margin {
      margin: 46px 0 15px;
    }
  }
  .small-collect-button {
    margin: 25px auto 10px;
  }
  .header_transactions-small-mode {
    display: none;
  }
  .version-dropdown-button {
    margin-bottom: 10px;
    display: block;
    margin-right: 0;
  }
  .transaction-padding{
    padding: 0.85rem 0rem !important;
  }
  .transaction-center{
    text-align: center !important;
  }
}

.hide {
  display: none !important;
}


.switch-box{
  border-radius: 40px !important;
  box-shadow: 0 0 30px 0 rgba(0,0,0,.16);
  border: 1px solid #3ab084;

  &:hover {
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25)
  }
}

.center {
  align-items: center;
  justify-content: center;
}

.justify-center {
  justify-content: center;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.add_company {
  border: none;
  background: transparent;
  color: #3ab084;
  padding-bottom: 20px;
  margin-left: 20px;

  &:hover {
    color: #28a745;
  }
}

.img_bar_chart {
  padding: 10px;
  margin-bottom: 4px;
}

.portfolio-list {
  line-height: 1.2;
}

.company-portfolio-list {
  line-height: 1.2;
}

.fixura_loans {
  background-color: #f0f0f0;
  line-height: 1.2;
  height: 18em;
  color: #000 !important;
}

.mb-1_add_company {
  margin-bottom: 1px;
}

.mb-3_add_company {
  margin-bottom: 3px;
}

.h-2 {
  height: 2em;
}

.text-white{
  color: #fff;
}

@media #{$lg}, #{$xl}{
  .lg-ml-20 {
    margin-left: 20em; 
  }
}

.max-w-900 {
  max-width: 900px;
}

.add_company-item__wrapper {
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  height: 80px;
}

.info-popup {
  color: #212529;
  font: 400 14px 'WorkSansMedium', Helvetica, sans-serif;
}

.popup-content {
  border-radius: 7px;
}

.px-10{
  padding-right: 10px;
  padding-left: 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3ab084;
  background-color: #3ab084;
}

.autoinvest .custom-control-label .loanAccountState{
  padding-top: 2px;
}

.green-color{
  color: #3ab084;
}

.pb-6 {
  padding-bottom: 6px;
}

.badge {
  padding: 5px 15px;
  color: #fff;
  font-family: WorkSansMedium,Helvetica,sans-serif;
  border-radius: 50px;
  font-size: 1rem;
  height: 27px;

  &__red {
    background-color: #FF7B84;
    border-color:#FF7B84;
  }
  
  &__green {
    background-color: #3ab084;
    border-color:#3ab084;
  }
  
  &__gray {
    padding: 5px 15px;
    background-color: #9E9E9E;
    border-color:#9E9E9E;
  }
}

/* small device */
@media #{$xs} {
  .sm-hide {
    display: none;
  }
  
  .margin-portfolio {
    margin-left: 3px !important;
  }
 
  .font-portfolio {
    font-size: 11px;
  }

  .widget-height {
    min-height: 15em !important;
  }

 .text-left {
   text-align: left !important;
 }

 .sm-pb-5 {
  padding-bottom: 3rem;
 }

 .withraw-funds-value {
    padding-left: 1.8rem;
    padding-bottom: 0px !important;
    justify-content: left !important;
  }
  
  .withraw-funds-align{
    text-align: left !important;
  }
  
  .withraw-funds-account {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

 .portfolio_suomi {
   padding: 10px 3rem 10px 10px !important;
 }

  .title-transfer{
    margin-left: 5px;
  }

  .collect-funds-input {
    margin: 0 10px;
  }


  .pl-12 {
    padding-left: 12px;
  }
  
  .img_bar_chart {
    padding: 3.8px;
  }
}

.withraw-funds-value{
  align-items: center;
  justify-content: center;
}

.withraw-funds-align{
  text-align: center;
}

.pointer {
  cursor: pointer;
}

/* xsmall device */
@media (max-width: 375px) {
  .margin-portfolio {
   margin-left: 3px !important;
  }

 .font-portfolio {
   font-size: 10px;
  }

  .portfolio_suomi {
    padding: 10px 3rem 10px 10px !important;
  }
}

.margin-portfolio {
  margin-left: 10px
}

.widget-height {
 min-height: 17em;
}

.portfolio_suomi {
  border-radius: 22px;
  background: gray;
  padding: 10px 3rem 10px 35px;
  font-size: 18px !important;
  color: #fff;
}

.portfolio_account{
  border-radius: 22px;
  background: white;
  padding: 10px 10px 10px 10px !important;
  font-size: 16px !important;

  &__name {
    color: #3ab084;
  }

  &__number {
    color: rgb(160, 156, 156);
  }
}

.pt-0 {
  padding-top: 0px;
}

.pt-10 {
  padding-top: 10px;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.text-left{
  text-align: left !important;
}

.title-transfer{
  margin-left: 25px;
}

.max-w-sm {
  max-width: 297px;
}

@media (max-width: 768px) {
  .right-arrow{
    display: none;
  }
}

@media (min-width: 769px) {
 .down-arrow{
   display: none;
  }
  .right-arrow{
    padding-top: 20px !important;
  }
}

.pb-10 {
  padding-bottom: 10px;
}

.cancel-btn {
  padding: 0 20px 0 20px !important;
  height: 48px;
  background-color: transparent !important;
  border-color: transparent !important;
  font-family: WorkSansMedium, Helvetica, sans-serif;
  border-radius: 50px;
  font-size: 1rem;
  color: color(green, green_main);

  &:hover {
    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
    color: #87d4b8 !important;
    border-color: transparent !important; 
  }

  &:active{
    background-color: transparent !important;
    border-color: transparent !important;
    color: rgb(224, 3, 3) !important;
  }

  &:focus {
    background-color: transparent !important;
    border-color: transparent !important;
    color: rgb(224, 3, 3) !important;
  }
}

.stop-withdraw-btn {
  padding: 0 20px 0 20px !important;
  height: 48px;
  background-color: #fff !important;
  border-color:  #fff !important;
  font-family: WorkSansMedium, Helvetica, sans-serif;
  border-radius: 50px;
  font-size: 1rem;
  color: #3ab084;

  &:hover{
    background-color:  #3ab084 !important;
    border-color:  #3ab084 !important;
    color: #fff !important;
  }

  &:active{
    background-color:  #3ab084 !important;
    border-color:  #3ab084 !important;
    color: #fff !important;
  }

  &:focus {
    background-color:  #3ab084 !important;
    border-color:  #3ab084 !important;
    color: #fff !important;
  }
}

.progress {
  height: 2.5rem;
  border-radius: 20px !important;
  background-color: color(gray, gray_800);

  span {
    text-align: end;
    position: absolute;
    display: block;
    width: 100%;
    color: #fff;
    padding-right: 2.5rem;
    font: 500 16px 'WorkSansMedium', Helvetica, sans-serif;
  }
}

.progress-bar {
  height: 2.5rem;
  border-radius: 20px;
  background: linear-gradient(45deg, rgb(208, 236, 255), #424ce0);
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.withdraw-collect-funds-title {
  color: #FFFFFF;
  text-align: center;
  margin-top: 55px;
  margin-bottom: 0px;
}

.withdraw-collect-funds {
  color: #FFFFFF;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
}

.alert-pr-5 {
  padding-right: 5px
}

.confirm-dialog-body-text {
  font-size: 16px;
  font-weight: 500;
}

.pt-8 {
  padding-top: 8px;
}

.pb-10 {
  padding-bottom: 10px;
}

.p-switch{
  padding: 0px 10px 20px 10px;
}

.p-title{
  padding: 40px 0px 25px 0px;
}

.pt-5 {
  padding-bottom: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.transaction-padding {
  padding: 0.75rem 0.15rem !important;
}

.height-terms {
  height: 1.6rem;
}