.walk-thru {
  &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #fff;
    }

    &__body {
      margin-top: 2rem;
    }

    &__title {
      margin-top: 2rem;
      padding: 0 4rem;
      font-size: 2.2rem;
    }

    &__detail {
      margin-top: 2rem;
      padding: 0 5rem;
      font-size: 1rem;
    }

    &__deposit-detail {
      margin-top: 1rem;
      font-size: 1rem;
      text-align: left;
      padding: 0 1rem;
      margin-left: 20%;

      &__main{
        font-size: 1rem;
        text-align: left;
        margin-left: 8%;
      }

      &__img{
        text-align: right;
      }
        

      &__info{
        font-size: 1rem;
        text-align: left;
      }
    }

    &__deposit-bullets {
      text-align: left;
      padding: 0 1rem;
      font-size: 1rem;

      &__title{
        font-family: WorkSansBold, sans-serif;
        margin-bottom: 0;
      }
    }

    &__bullets {
      text-align: left;
      margin-top: 1rem;
      padding: 0 5rem;
      font-size: 1rem;
      margin-left: 5%;

      &__title{
        font-family: WorkSansBold, sans-serif;
        margin-bottom: 2px !important;
      }
    }

    &__btn {
      margin: 20px 5px 20px;

      &:focus {
        box-shadow: none;
      }
    }
}

.col-p {
  padding-left: 0 !important;
}

.pb-2 {
  padding-bottom: 2px;
}

@media #{$sm} {
  .walk-thru {
    &__container {
      width: 100%;
    }

    &__body {
      margin-top: 2rem;
    }

    &__title {
      margin-top: 1rem;
      padding: 0 1rem;
    }

    &__detail {
      margin-top: 1rem;
      padding: 0 1rem;
    }

    &__bullets {
      padding: 0 1rem;
      margin-left: 4%;
    }

    &__deposit-detail {
      padding: 0 0;
      margin-left: 5%;

      &__main{
        margin-left: 0%;
      }

      &__icon{
        text-align: end;
      }
    }

    &__deposit-bullets {
      margin-top: 0;

      &__title{
        font-family: WorkSansBold, sans-serif;
        margin-bottom: 0;
      }
    }

    &__btn {
    display: initial !important;
    width: 36% !important;  
    }
  }

  .col-p {
    padding-right: 0 !important;
    padding-left: 15px !important;
  }

  #walk-thru-investor .steps-container .steps-list li {
    width: 14.5%;
  }
}

@media #{$xs} {
  .walk-thru__deposit-bullets {
    padding: 0 1.4rem;
  }
}