.gather-information {
  .spinner__wrapper {
    min-height: 200px;
  }

  &__title {
    text-align: center;
    margin-top: 5rem;
  }
}

.grey-bg {
  background-color: color(gray, gray_300) !important;
}