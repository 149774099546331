@import "../../variables";

.next-installment {
  padding: 3rem 1rem;

  &__header {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  .next-installment-info__wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .next-installment-info {
      font-size: 16px;

      &__title {
        font-weight: 500;
      }

      &__value {
        font-family: WorkSansBold, Helvetica, sans-serif;
      }
    }
  }
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .next-installment {
    padding: 2rem 1rem;

    &__header {
      font-size: 25px;
      margin-bottom: 1rem;
    }

    .next-installment-info__wrapper {
      flex-direction: column;
      align-items: flex-start;

      .next-installment-info {
        margin-bottom: 14px;

        &.download-invoice {
          align-self: center;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (min-width: 575px) { 
  .interest-rate {
    display: none;
  }
}