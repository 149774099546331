@import "variables";

.steps-container {
  display: block;
  min-height: 80px;
  width: 96%;
  margin-left: 2%;

  .steps-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;

    li {
      list-style-type: none;
      width: 20%;
      float: left;
      position: relative;
      text-align: center;

      &.active .radial {
        background-color: color(blue, blue_main);
      }

      &:first-child .radial {
        display: none;
      }

      &.active {
        color: color(blue, blue_main);
        border-color: color(blue, blue_main);

        &:before {
          border-color: color(blue, blue_main);
          background-color: color(blue, blue_main);
        }
      }

      &:before {
        width: 30px;
        height: 30px;
        content: '';
        line-height: 30px;
        border: 2px solid whitesmoke;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: whitesmoke;
      }

      .radial {
        width: 97.5%;
        height: 30px;
        content: '';
        position: absolute;
        background-color: color(gray, gray_080);
        top: 0;
        left: -50%;
        z-index: -2;
        display: flex;
        justify-content: space-between;

        &::before {
          content: '';
          width: 20px;
          height: 40px;
          border-radius: 0 40px 40px 0;
          background-color: #FFF;
          display: inline-block;
          vertical-align: middle;
          margin-top: -5px;
        }

        &:after {
          content: '';
          width: 20px;
          height: 40px;
          border-radius: 40px 0 0 40px;
          background-color: #FFF;
          display: inline-block;
          vertical-align: middle;
          margin-top: -5px;
          margin-right: -5px;
        }
      }
    }
  }
}

/* ====================================
              Media Queries
======================================*/
@media #{$md} {
  .steps-container .steps-list {
    li {
      font-size: 14px;
    }
  }
}

@media #{$sm} {
  .steps-container .steps-list {
    justify-content: space-between;

    li .radial {
      width: 193%;
      left: -150%;
    }
    li {
      font-size: 10px;
    }
  }
}

.responsive-ccis{
  margin: auto;
  width: 70%
}

.ccis-box{
  padding: 0px 20px 40px 120px !important;
}

.p-20{
  padding: 0px 20px 20px 20px;
}

.ml-10{
  margin-left: 10px;
}

.ml-20{
  margin-left: 20px;
}

.ml-50{
  margin-left: 50px;
}

.ml-75{
  margin-left: 75px;
}

.ml-68{
  margin-left: 68px;
}

.circle-fill-ccis{
  position: absolute;
  margin-left: 7px;
}

.pt-4{
  padding-top: 4px !important;
}

.ccis_bulltet{
  position: absolute;
  margin-left: 20px;
  padding: 0.5em 0.75em 0.5em 0.75em;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ccis_badge-primary {
  color: #fff;
  background-color: black;
}

@media #{$xs} {
  .px-15{
   padding-left: 15px;
   padding-right: 15px;
  }

  .px-25{
    padding-left: 25px;
    padding-right: 25px;
  }
}

.mb-2 {
  margin-bottom: 2% !important;
}