@import "../variables";

.customer-service {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;

  .service-img {
    width: 87px;
  }

  .service-title {
    font-size: 28px;
    margin-bottom: 0;
    margin-top: .5rem;
  }
}

/* ====================================
              Media Queries
======================================*/
@media #{$sm} {
  .customer-service {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .top-title {
      display: none;
    }

    .service-title {
      font-size: 20px;
    }
  }

  .dashboard-customer-service {
    .top-title {
      display: none;
    }

    .service-title {
      font-size: 20px;
    }

    .customer-service-body{
      padding-bottom: 40px;
    }
  }

}

.dashboard-customer-service {
  background-color: rgb(240,240,240);

  .service-img {
    width: 87px;
  }

  .service-title {
    font-size: 28px;
    margin-bottom: 0;
    margin-top: .5rem;
  }

  .customer-service-header{
    padding-top: 40px;
  }

  .customer-service-body{
    padding-bottom: 40px;
  }
}