//alert toast css
.toast {
    max-width: 80%;
    left: 20%;
    right: 20%;
    top: 7rem;
    position: absolute;
    z-index: 1050;
    display: block
}

@media #{$sm} {
    .toast {
        max-width: 100% !important;
        left: 0% !important;
        right: 0% !important;
        top: 4rem;
    }
}


.toast-alert {
    color: #fff;
    background: #FF7B84;
    padding: 1rem 3rem;

    button {
        top: 0;
        position: absolute;
        right: 0;
        padding: 2px 7px;
    }
}