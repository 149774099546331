@import "../../variables";
.view-offer {
  .offer-information {
    background-color: color(blue, blue_main);

    &__title {
      color: color(blue, blue_400);
      height: 3rem;
    }

    &__year-title {
      color: color(blue, blue_400);
      height: 3.4rem;
    }

    &__value {
      color: #fff;
    }

    &__box {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
    }

    .show > .btn-secondary.dropdown-toggle {
      background: #87d4b8 !important;
      color: #fff !important;
      border-color: transparent !important; 
    }
  }

  .offer-information__btn {
    .btn-group.show button {
      color: color(green, green_main);
    }

    .offer-information__title {
      color: color(blue, blue_400);
      padding-bottom: 1rem;
      padding-left: 5px;
    }

    .offer-information__year-title {
      color: color(blue, blue_400);
      padding-bottom: 1rem;
      padding-left: 5px;
    }

    .account-name {
      color: color(green, green_main);
    }
  }

  .view-offer__dropdown {
    width: 250px;
  }

  .dropdown-menu {
    width: 250px;

    .dropdown-item {
      padding: 1rem 1.9rem;
      color: color(green, green_main);
      text-align: center;

      &:active {
        background-color: #f1f1f1;
      }
    }
  }
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .view-offer {
    .your-loan-offer__header {
      font-size: 40px;
      line-height: 1.5;
    }

    .offer-information__title {
      height: 2rem;
      margin-top: 2rem;
    }

    .offer-information__year-title {
      height: 3.4rem;
      margin-top: 2rem;
    }
  }
}

.new_investor {
  background-color: #6195F0;
  line-height: 1.2;
  height: 13em;

  &__text {
    font-size: 1.0rem ;
   }
}


@media (min-width: 410px), (max-width: 720px) {
  .new_investor {
   height: 16em;
  }
}

@media (max-width: 409px) {
  .new_investor {
   height: 19em;
  }
}

.hide {
  display: none !important;
}