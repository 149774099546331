@import "variables";

body {
  font: 500 16px 'WorkSansMedium', Helvetica, sans-serif;
}

h1 {
  font: 40px "League Spartan", Futura, Arial, sans-serif;
}

h2 {
  font: bold 30px WorkSansBold, Helvetica, sans-serif;
}

h3 {
  font: 600 20px WorkSansSemiBold, Helvetica, sans-serif;
}

.font-80 {
  font: 80px "League Spartan", sans-serif;
}

.font-40 {
  font: 40px "League Spartan", sans-serif;
}

.font-50 {
  font: 50px "League Spartan", Futura, Arial, sans-serif;
}

.font-28 {
  font: bold 28px WorkSansBold, Helvetica, sans-serif;
}

.font-25 {
  font: 25px "League Spartan", sans-serif;
}

.font-13 {
  font: 13px WorkSansMedium, Helvetica, sans-serif;
}

.font-20 {
  font: bold 20px WorkSansRegular, sans-serif;
}

.font-20-bold {
  font: bold 20px WorkSansBold, sans-serif;
}

.font-20-body {
  font: 500 20px WorkSansBold, Helvetica, sans-serif;
}

/* ====================================
              Media Queries
======================================*/
@media #{$sm} {
  h1 {
    font-size: 25px;
  }

  h3 {
    font-size: 16px;
  }

  .font-80 {
    font-size: 60px;
  }

  /* fixme */
  .font-50 {
    font-size: 24px;
  }

  .font-60-sm {
    font-size: 50px !important;
  }
}

@media #{$lg} {
  .font-80 {
    font-size: 60px;
  }
}
