@import "../../mixins";

.phone {
  INPUT[type=checkbox] {
    @include checkbox('green');
  }

  .receive-offers__wrapper {
    text-align: left;
    .form-check-input
    {
      margin-top: 2px;
    }
    .receive-offers__title {
      margin-left: 1rem;
    }
  }
}
