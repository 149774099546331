.fixura-logo__wrapper {

  .fixura-mark {
    margin-left: 10px;
  }

  &.small-logo {
    .fixura-logo {
      height: 65px;
    }
  }

  &.medium-logo {
    .fixura-logo {
      height: 15.5px;
    }
  }

  &.large-logo {
    .fixura-logo {
      height: 65px;
    }
  }
}
