@import url(https://fonts.googleapis.com/css?family=Roboto);
@import "../mixins";

$md-radio-checked-color: rgb(51, 122, 183);
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

.aml__wrapper {
  h1 {
    margin-top: 84px;
    margin-bottom: 77px;
  }


  .step-top-pad {
    padding-top: 5em;
  }

  .center-column {
    text-align: center;
  }

  .input-distance {
    margin-bottom: 128px;
  }

  .input-characteristics {
    background: rgb(245, 245, 245);
    border-radius: 5px;
    height: 60px;
    border: 0px;
    width: 100%;
    padding: 1.5rem;
    font: 500 16px 'WorkSansMedium';
  }

  .hide-field {
    display: none;
  }

  .row-bottom-distance {
    margin-bottom: 54px;
  }

  .radio-characteristics {
    border: 1px solid rgb(34, 22, 176);
    height: 24px;
    width: 24px;
    margin-top: 0;
  }

  .option-look {
    margin-left: 0;
    margin-right: 20px;
  }

  .top-bottom-distance {
    margin-bottom: 80px;
    margin-top: 115px;
  }

  .big-distance-bot {
    margin-bottom: 128px;
  }

  .continue-distance {
    margin: 54px auto 148px;
  }
}

@keyframes ripple {

  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.radio-button {
  margin: 16px 0;

  input[type="checkbox"] {
    @include checkbox('green');
  }

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 2px ($md-radio-size + 20px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      border: 1px solid rgb(34, 22, 176);
      height: 24px;
      width: 24px;
    }

    &:after {
      top: 0;
      left: 0;
      background: rgb(41, 43, 203);
      border: 1px solid rgb(41, 43, 203);
      height: 24px;
      width: 24px;
      transform: scale(0);
    }
  }
}

.check-box {
  margin: 16px 0;

  input[type="checkbox"] {
    @include checkbox('green');
  }

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: unset;
    height: 21px;
    position: relative;
    padding: 0 ($md-radio-size);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 10%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
  }
}

/* ====================================
              Media Queries
======================================*/
@media #{$sm} {
  .aml__wrapper {
    h1 {
      font-size: 40px;
    }
  }
}

.center-text {
  text-align: center;
}

.pt-10 {
  padding-top: 10px;
}

.pl-10 {
  margin-left: 10px !important;
}

.pep-check {
  &__container {
      display: flex;
      flex-direction: column;
      background: #fff;
  }

  &__body {
    padding: 1.75rem 4.75rem 0 4.75rem;
  }

  &__btn {
    text-align: center;
    padding: 0 0 1.75rem 0 !important;
  }
}


@media #{$lg}, #{$xl}, #{$md} {
  .md-hide{
    display: none;
  }

  .link-pep{
    padding-left: 4px !important;
    padding-bottom: 4.5px;
  }
}

@media #{$sm} {
  .pep-check {
    &__body {
      padding: 1.75rem 0.75rem 0 0.75rem !important;
    }
  }
  .link-h-20 {
    height: 20px;
  }
}