.tc-modal {
  overflow-y: initial !important;
  border-radius: 5px;
  margin-top: 5rem;

  .modal-content {
    padding: 1rem;
    background: #fff;
    color: #000000;
    justify-content: center;
    text-align: center;
  }

  .modal-header {
    border-bottom: none
  }

  .modal-header >button {
    color: #000000;
  }

  .p-0 {
    padding: 0px;
  }

  .modal-body {
    height: auto;
    overflow-y: auto;

    .term-title {
      font: 20px WorkSansBold;
      display: block;
      margin-bottom: 1rem;
    }

    @media #{$xs} {
      .term-title {
        font: 16px WorkSansBold;
      }
    }
  }

  .modal-title {
    font: 24px WorkSansBold;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    border-top: none;
  }
}

.custom-class-tc-2023 {
  max-width: 380px;
  margin: auto;
}