@import "../../variables";

.loan-rejection__wrapper {
  width: 100%;
}

.loan-reject-message__container {
  background-color: color(gray, gray_100);
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loan__main-title {
  font-family: "League Spartan", sans-serif;
  font-size: 50px;
}

.loan-rejection__description {
  font-family: WorkSansMedium, sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-top: 1rem;
}

.loan-back-to-home__btn {
  margin-top: 98px;
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .loan-reject-message__container {
    height: initial;
  }

  .reject-message__container {
    padding: 1rem;
  }

  .loan__main-title {
    font-size: 40px;
    margin-top: 6rem;
    text-align: center;
  }

  .loan-rejection__description {
    text-align: center;
  }

  .loan-back-to-home__btn {
    padding: 1rem !important;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .loan-reject-redirect-message__container {
    padding: 0 1rem !important;
  } 

  .redirect-btn {
    padding-top: 10px !important;
  }
}

.loan-reject-redirect-message__container {
  padding: 0 10rem;
  background-color:color(gray, gray_100);
  color: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 5rem 20rem;
}

.pb-15{
  padding-bottom: 15px;
}

.redirect-btn {
  padding-top: 1rem;
}

//unique-id used GTM
.redirect-broker-btn{
  margin-top: 38px;
}