@import "variables";

@mixin arrowSignPseudo($side, $color) {
  content: '';
  border: solid $color;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-width: 0 2px 2px 0;

  @if ($side == 'left') {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: 10px;
  } @else if ($side == 'right') {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 10px;
  }
}

@mixin btnLinkStates($state) {
  $color: null;

  @if ($state == 'focus') {
    $color: color(green, green_400);
  } @else if ($state == 'active') {
    $color: color(green, green_600);
  } @else if ($state == 'disabled') {
    $color: color(gray, gray_300);
  }

  color: $color !important;
  box-shadow: none;
  outline: 0;

  &.left-arrow:before,
  &.right-arrow:after {
    border-color: $color;
  }
}

.btnWithRightArrow {
  @include arrowSignPseudo('right', white);
  margin-left: 0;
}

.btn {
  padding: 0 20px 0 20px !important;
  height: 48px;
  background-color: color(green, green_main) !important;
  border-color: color(green, green_main) !important;
  font-family: WorkSansMedium, Helvetica, sans-serif;
  border-radius: 50px;
  font-size: 1rem;

  &:hover{
    background: #87d4b8 !important;
    color: #fff !important;
    border-color: transparent !important; 
  }

  &.light {
    background-color: white !important;
    border-color: white !important;
    color: color(green, green_main);

    &.right-arrow:before,
    &.just-right-arrow:before {
      border-color: color(green, green_400);
    }

    &:focus {
      background-color: color(gray, gray_080) !important;
    }

    &:active {
      background-color: color(green, green_200) !important;
    }

    &:disabled {
      background-color: color(gray, gray_200) !important;
      border-color: color(gray, gray_200) !important;
    }

    &:hover {
      background: #87d4b8 !important;
      color: #fff !important;
      border-color: transparent !important; 
    }

    &.btn-link {
      color: color(green, green_400);

      &.left-arrow:before,
      &.right-arrow:after {
        color: color(green, green_400);
      }
    }
  }

  &.light-grey {
    background-color: rgb(238, 238, 238) !important;
    border-color: rgb(238, 238, 238) !important;
    color: color(green, green_main);

    &.right-arrow:before,
    &.just-right-arrow:before {
      border-color: color(green, green_400);
    }

    &:focus {
      background-color: color(gray, gray_080) !important;
    }

    &:active {
      background-color: color(green, green_200) !important;
    }

    &:disabled {
      background-color: color(gray, gray_200) !important;
      border-color: color(gray, gray_200) !important;
    }

    &.btn-link {
      color: color(green, green_400);

      &.left-arrow:before,
      &.right-arrow:after {
        color: color(green, green_400);
      }
    }
  }

  &:focus {
    background-color: color(green, green_500) !important;
  }

  &:active {
    background-color: color(green, green_600) !important;
  }

  &:disabled {
    background-color: color(gray, gray_300) !important;
    border-color: color(gray, gray_300) !important;
  }

  &.right-arrow:before {
    @extend .btnWithRightArrow;
    margin-right: 15px;
  }

  &.just-right-arrow {
    width: 48px;

    &:before {
      @extend .btnWithRightArrow;
      margin-right: 3px;
      margin-left: -3px;
    }
  }

  &.hero {
    height: 60px;
    padding-right: 20px !important;
    padding-left: 20px !important;

    &.just-right-arrow {
      width: 60px;
    }
  }

  &.small {
    padding: 2px 10px !important;
    height: initial;
  }

  &.btn-link {
    padding: 0 !important;
    background-color: transparent !important;
    color: color(green, green_main);
    border: 0;
    text-decoration: none;

    &.left-arrow:before {
      @include arrowSignPseudo('left', color(green, green_main));
    }

    &.left-arrow:after {
      display: none;
    }

    &.right-arrow:after {
      @include arrowSignPseudo('right', color(green, green_main));
    }

    &.right-arrow:before {
      display: none;
    }

    &:focus {
      @include btnLinkStates('focus');
      box-shadow: none;
    }

    &:active {
      @include btnLinkStates('active');
    }

    &:disabled {
      @include btnLinkStates('disabled');
    }

    &:hover {
      background: transparent !important;
      border: transparent !important;
      box-shadow: none !important;
      color: #424ce0 !important;
      border-color: transparent !important; 
    }
  }
  
  &.btn-pep-link {
    padding: 0 !important;
    background-color: transparent !important;
    color: color(green, green_main);
    border: 0;
    text-decoration: none;
  
    &.left-arrow:before {
      @include arrowSignPseudo('left', color(green, green_main));
    }
  
    &.left-arrow:after {
      display: none;
    }
  
    &.right-arrow:after {
      @include arrowSignPseudo('right', color(green, green_main));
    }
  
    &.right-arrow:before {
      display: none;
    }
  
    &:focus {
      @include btnLinkStates('focus');
      box-shadow: none;
    }
  
    &:active {
      @include btnLinkStates('active');
    }
  
    &:disabled {
      @include btnLinkStates('disabled');
    }
  
    &:hover {
      background: transparent !important;
      border: transparent !important;
      box-shadow: none !important;
      color:#424ce0!important;
      text-decoration: none;
      border-color: transparent !important; 
    }
  }
}

.mobile-phone-btn{
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}

.min-w-10 {
  min-width: 10rem;
}
