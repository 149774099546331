.wd-modal {
  overflow-y: initial !important;

  .modal-content {
    padding: 1rem;
    background: #424ce0;
    color: #fff;
    justify-content: center;
    z-index: 100000;
    text-align: center;
    font-family: "Work Sans",sans-serif;
    border-radius: 15px;
    border-color: #424ce0;
  }

  .modal-header {
    border-bottom: none
  }

  .modal-header >button {
    color: #000000;
  }

  .p-0 {
    padding: 0px;
  }

  .modal-body {
    height: auto;
    overflow-y: auto;

    .term-title {
      font: 20px WorkSansBold;
      display: block;
      margin-bottom: 1rem;
    }

    @media #{$xs} {
      .term-title {
        font: 16px WorkSansBold;
      }
    }
  }

  .modal-title {
    font: 24px WorkSansBold;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    border-top: none;
  }

  @media #{$sm} {
    .modal-content {
      left: 0 !important;
      width: 100% !important;
    }
   }
}

