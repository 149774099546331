@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/leagueSpartan/leaguespartan-bold.eot');
  src: url('../fonts/leagueSpartan/leaguespartan-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/leagueSpartan/leaguespartan-bold.woff2') format('woff2'),
  url('../fonts/leagueSpartan/leaguespartan-bold.woff') format('woff'),
  url('../fonts/leagueSpartan/leaguespartan-bold.ttf') format('truetype');
  //url('../fonts/leagueSpartan/leaguespartan-bold.svg#league_spartanbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansMedium';
  src: url('../fonts/workSans/WorkSans-Medium.ttf');
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
}

@font-face {
  font-family: 'WorkSansLight';
  src: url('../fonts/workSans/WorkSans-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansExtraLight';
  src: url('../fonts/workSans/WorkSans-ExtraLight.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansRegular';
  src: url('../fonts/workSans/WorkSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansThin';
  src: url('../fonts/workSans/WorkSans-Thin.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansBold';
  src: url('../fonts/workSans/WorkSans-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansExtraBold';
  src: url('../fonts/workSans/WorkSans-ExtraBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansSemiBold';
  src: url('../fonts/workSans/WorkSans-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSansBlack';
  src: url('../fonts/workSans/WorkSans-Black.ttf');
  font-weight: normal;
  font-style: normal;
}
