@import "../../variables";

.loan-calculator__wrapper {
  background-color: color(blue, blue_main);
  color: white;
}

.monthly-pay__dotted-line {
  width: 100%;
  border-bottom: 8px dotted color(blue, blue_900);
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .loan-calculator__wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}
