/* ====================================
            Colors Palette
======================================*/
// https://blog.yipl.com.np/defining-color-palette-in-sass-with-variables-6464c3c3b5e5

$palettes: (
  gray: (
    gray_900: #212121,
    gray_800: #424242,
    gray_700: #616161,
    gray_600: #757575,
    gray_500: #9e9e9e,
    gray_400: #bdbdbd,
    gray_300: #e0e0e0,
    gray_200: #eeeeee,
    gray_100: #f5f5f5,
    gray_080: #f8f8f8,
    gray_050: #fafafa,
    gray_020: #f3f3f3,
    gray_010: #b2b2b2
  ),
  blue: (
    blue_200: #e3f1ff,
    blue_300: #d2e9ff,
    blue_400: #b9dcfd,
    blue_500: #6188ff,
    blue_main: #424ce0,
    blue_700: #292bcb,
    blue_800: #2216b0,
    blue_900: #171069
  ),
  green:(
    green_200: #ccf3e4,
    green_300: #b1decd,
    green_400: #87d4b8,
    green_500: #63bf9c,
    green_main: #3ab084,
    green_600: #348667
  ),
  red:(
    red_300: #ffb0b5,
    main: #ff7b84
  ),
  yellow:(
    yellow_300: #f6f69e,
    main: #e8e836,
  ),
  orange:(
    orange_300: #ffdaae,
    orange_400: #ffb448
  )
);

@function color($color,$tone) {

  @if map-has-key($palettes, $color) {
    $color: map-get($palettes, $color);

    @if map-has-key($color, $tone) {
      $tone: map-get($color, $tone);
      @return $tone;
    }

    @warn "unknown tone `#{$tone}` in color";
    @return null;
  }

  //warning
  @warn "unknown color `#{$color}` in palette";
  @return null;
}

/* ====================================
      Media Queries Break Points
======================================*/

$tiny: "(max-width: 320.98px)";
$mobile: "(min-width: 321px) and (max-width: 767.98px)";
$tablet: "(min-width: 768px) and (max-width: 1023.98px)";
$desktop: "(min-width: 1024px)";

$small: "(max-width: 992px)";
$large: "(min-width: 992px)";

// bootstrap break-points
$xs: "(max-width: 420px)";
$sm: "(max-width: 768px)";
$md: "(min-width:768px) and (max-width: 992px)";
$lg: "(min-width: 992px) and (max-width: 1200px)";
$xl: "(min-width: 1200px)";
