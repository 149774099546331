.load-more_btn {
  display: inline-block;

  &:before {
    content: '';
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    width: 5px;
    height: 8px;
    margin-right: 10px;
    top: 0;
  }

  &:hover {
    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
    color: #ff7b84 !important;
    border-color: transparent !important; 
    &.more:before {
        border-top: 5px solid #ff7b84;
    }
  }

  &.more:before {
    border-top: 5px solid color(green, green_main);
  }

  &.less:before {
    border-bottom: 5px solid color(green, green_main);
  }
}
