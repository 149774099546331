@import "../variables";
@import "../mixins";
@import "contracts";

.profile {
  margin-top: 2rem;
  min-height: 72vh;

  .user-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .marketing__title {
    font-family: WorkSansBold, Helvetica, sans-serif;
  }
}

.pt-30 {
  padding-top: 30px;
}

.company-title {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: .5rem;
}