@import "../variables";

.code {
  .step-big-title {
    margin-bottom: .5rem;
  }

  .verification-code__form {
    text-align: center;

    INPUT[type=text] {
      width: 68px;
      height: 60px;
      background: color(gray, gray_100);
      border-radius: 5px;
      display: inline-block;
      margin: 0 5px;
      text-align: center;
      line-height: 100px;
      outline: none;
      transition: all .2s ease-in-out;
      font: 28px bolder WorkSans-Bold, sans-serif;
      padding-left: 0;
    }

    .asiakastieto-information {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .asiakastieto-information__wrapper {
        display: flex;
        background: color(blue, blue_main);
        color: white;
        justify-content: center;
        align-items: flex-start;
        margin-top: 2.5rem;
        border-radius: 5px;
        padding: 1rem;

        .asiakastieto-information__img {
          margin-top: 10px;
        }

        .asiakastieto-information__text {
          padding-bottom: 0;
          padding-top: .5rem;
          padding-left: 10px;
          text-align: left;

          a {
            text-decoration: underline;
            color: white;
          }
        }
      }
    }

    button {
      display: block;
    }
  }
}

.validation-error {
  color: red;
}
.error-align {
  text-align: left;
}
