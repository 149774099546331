.contracts-shared {
  background-color: white;
  color: black;
  padding: 15px;

  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  pre,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-family: sans-serif;
  }

  .logo {
    height: 50px;
    margin-bottom: 30px;
    opacity: 0.9;
  }

  h3 {
    font-size: larger;
    margin-bottom: 15px;
  }

  p {
    line-height: 22px;
    margin-bottom: 15px;
  }

  .footer {
    width: 100%;
    margin-top: 50px;
  }

  .footer td {
    width: 50%;
    padding-right: 50px;
  }

  .footer img {
    height: 100%;
    width: auto;
  }

  .signature {
    height: 50px;
  }

  hr {
    border: 1px solid black;
  }

  .user-data {
    width: 100%;
  }

  .user-data td {
    width: 50%;
    padding: 0;
  }

  .definitions {
    border-spacing: 10px;
    border-collapse: separate;
    width: 100%;
  }

}
