@import "../variables.scss";
@import "../mixins.scss";

.sign-investor-contract {
    &__center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  .check-boxes__wrapper {
    width: 65%;
    padding: 1rem;
    .checkbox__wrapper {
      display: flex;
      margin-bottom: 1rem;
      input {
        @include checkbox('green');
        min-width: 18px;
      }
    }
  }
}

@media #{$sm} {
  .sign-investor-contract {
    &__center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .check-boxes__wrapper {
      width: 95%;
    }
  }
  .terms-and-conditions {
    margin: 0px !important;
  }
  .terms-and-conditions-btn {
    white-space: nowrap;
    font-size: 14px;
  }
}

.text-left {
  text-align: left !important;
}

.p-l-1 {
  padding-top: 0 !important;
  padding-left: 1rem !important;
}

.p-l-1-b-0 {
  padding-left: 1rem !important;
  padding-bottom: 0 !important;
}

.terms-and-conditions {
  margin-left: 12rem;
}