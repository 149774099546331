@import "../variables";

.download-item {
  background-color: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;

  .download-item__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.0rem;
    height: 80px;

    .download-item--title {
      font-family: WorkSansBold, Helvetica, sans-serif;
      font-weight: bolder;
    }

    .download-invoice__link {
      color: color(green, green_main);
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: color(green, green_main) !important;
      }

      img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
    }
  }
}
