@import "../variables.scss";
@import "../mixins.scss";

.contract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__frame {
    width: 65%;
    background-color: color(gray, gray_050);
    max-height: 400px;
    overflow: auto;
    padding: 1rem;
  }

  @media #{$sm} {
    &__frame {
      width: 100%;
    }
  }
  
  &__content {
    line-height: 1.4rem;
  }

}
