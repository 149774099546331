@import "../variables";
@import "components/loanRejection";
@import "components/identityVerification";
@import "components/provideDetails";
@import "components/gatherInformation";

.borrower-onboarding {
  .step-big-title {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.ccis-step-big-title {
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.min-h-72 {
  min-height: 72vh;
}

.legacy_loan {
  &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #fff;
    }
    &__header{
      border-bottom: none;
      padding: 0.75rem 0.75rem 0px 0.75rem;
    }
    &__body {
      padding: 5px 2rem 1rem 2rem;
    }
}