@import "../../variables";

$CircleSize: 400px;

// $part: first | second
// $isShadow: true | false
@mixin line($part, $isShadow: false) {
  $animationDelay: 0s;
  $rotateDeg: 45deg;
  $left: 120px;
  $top: 160px;
  $borderRadius: 15px;
  $bgColor: color(blue, blue_700);
  $zIndex: 9;


  @if ($part == 'second') {
    $animationDelay: .6s;
    $rotateDeg: -40deg;
    $left: 165px;
    $top: 150px;
    $borderRadius: 0 15px 15px 9px;
  }

  @if ($isShadow == false) {
    -webkit-animation: animate-line .5s forwards $animationDelay;
    /* Safari 4+ */
    -moz-animation: animate-line .5s forwards $animationDelay;
    /* Fx 5+ */
    -o-animation: animate-line .5s forwards $animationDelay;
    /* Opera 12+ */
    animation: animate-line .5s forwards $animationDelay;
    $bgColor: white;
    $zIndex: 10;
  }

  @if ($isShadow == true) {
    width: 100%;
  }

  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
  display: block;
  position: absolute;
  background-color: $bgColor;
  height: 40px;
  z-index: $zIndex;
  -moz-transform: rotate($rotateDeg);
  -webkit-transform: rotate($rotateDeg);
  transform: rotate($rotateDeg);
  left: $left;
  top: $top;
  border-radius: $borderRadius;
}

@mixin animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.thank-you {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 0;

  .thank-you-tick__circle {
    width: $CircleSize;
    height: $CircleSize;
    border-radius: 50%;
    background-color: color(blue, blue_main);
    margin-bottom: 65px;
    position: relative;

    .tick-first-part {
      width: 135px;
      height: 100px;
      position: relative;

      .line-1 {
        @include line('first');
      }

      .line-1-shadow {
        @include line('first', true);
      }
    }

    .tick-second-part {
      width: 190px;
      height: 200px;
      position: relative;

      .line-2 {
        @include line('second');
      }

      .line-2-shadow {
        @include line('second', true);
      }
    }
  }
}

@-webkit-keyframes animate-line {
  @include animation;
}

@-moz-keyframes animate-line {
  @include animation;
}

@-o-keyframes animate-line {
  @include animation;
}

@keyframes animate-line {
  @include animation;
}
