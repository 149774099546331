@import "../variables";

.login {
  padding: 5rem;

  label.verify-phone-number-input,
  .verify-phone-number-input>input {
    display: block;
    width: 100%;
  }

  .login-method__title {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  .login-email__form input {
    width: 100%;
    margin-bottom: 1rem;
  }

  .email-login-spinner {
    position: absolute;
    left: 43%;
    top: -1rem;
    padding: 1rem 3rem;
    z-index: 3;
  }

  .login-enter-phone-form {
    margin-top: 5rem;
  }

  .login-wraper {
    text-align: center;

    .accept-term {
      text-align: left;
    }
  }

  .login-link {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-credential__wrapper {
    .select-sca-spinner {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffffff00;
      z-index: 3;
      width: 100%;
      height: 100%;
      top: 0;
    }

    .sca__wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .sca-item {
        width: calc(16.66% - 2rem);
        height: 109px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: whitesmoke 0 0 30px 20px;
        margin: 1.5rem 1rem 0 1rem;
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .login {
    padding: 2rem 1rem;
  }

  .login-p-5{
    padding: 2rem 2rem 3rem 2rem !important;
  }

  .login-link {
    padding: 0.2rem 0.9rem 0.2rem;
    justify-content: left !important;
  }
}

.color-red {
  color:#FF7B84!important;
}

.login-p-5{
  padding: 5rem 5rem 3rem 5rem;
}

.error-message-item {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
}

.marketing {
  padding: 5px;
}