@import "variables";

// Color: 'green', 'light-green', 'gray', 'black'
@mixin checkbox($color) {
  $selectedColor: null;
  $bgImage: null;

  @if ($color == 'green') {
    $selectedColor: color(green, green_main);
    $bgImage: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMTggMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYzLjEgKDkyNDUyKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbnMvaWNfY2hlY2tfYm94IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMy4wMDAwMDAsIC0zLjAwMDAwMCkiIGZpbGw9IiMzQUIwODQiPgogICAgICAgICAgICA8ZyBpZD0iSWNvbi0yNHB4Ij4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xOSwzIEw1LDMgQzMuODksMyAzLDMuOSAzLDUgTDMsMTkgQzMsMjAuMSAzLjg5LDIxIDUsMjEgTDE5LDIxIEMyMC4xMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSw1IEMyMSwzLjkgMjAuMTEsMyAxOSwzIEwxOSwzIFogTTEwLDE3IEw1LDEyIEw2LjQxLDEwLjU5IEwxMCwxNC4xNyBMMTcuNTksNi41OCBMMTksOCBMMTAsMTcgTDEwLDE3IFoiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=';
  } @else if ($color == 'gray') {
    $selectedColor: color(gray, gray_400);
    $bgImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAASFJREFUSA3tldERgjAMhtueYzgEIwBvrqA+u4a4hhOgC/jEwRC6AWMImF8N1/aoUOojuYMEmvxfSXOHEIuNdEDq61VVbdq2PXddt9bfT42llLVS6hDH8Y1rFAfwIeKox8aggZjNAMzdOYvB2xoGQE/0iak1d1f+PwB5kiQR9T4bgoQC8jRN9/QFDfXeGBiGhQB68aIosPsji+reCaBdPfREK54kjhoX4ESAiK6rJYzHyeJIdgEEHdyTri1BLkj8mpc4alZcafkj9VWQeEZzvSvLEvPd8YH+6rml4wQgz4DgBQEbH3HUuL4Aa7AeggdfcdSMAZDzhiAgGxzFz9LwfQpgljDjnFPECaHeANAh1qGCtoYBwM/CTvABohYaPjVLrngBVJdxuI/IoUsAAAAASUVORK5CYII=';
  } @else if ($color == 'light-green') {
    $selectedColor: color(green, green_400);
    $bgImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATFJREFUSA3tlTFSAjEUht/POOMlrKBbGjprGhgvYKHWnsHOtdIzeALlAgyew6Wz8xhqfH/GzLzEDZBNS4rNY/O/79vJBhA5jj07ALv+1G0uxLlnJ3Jm7x9aK+xTgNu76XIdekah8HMFnP3+wZRhmZFg6JNbYMqIBDZYVAPvuXy1AIKXSbOYjYC2T1IlIHw8XdxcAt8/ItGBCbLBAgt/7Datnr77ALVzVgBIZ4O2PhTOnn4B8HDenM5UsrJg1iVw5vsFujDH/GvcLK/0i/PKIEcpnD0nvPwbup+6r6Ivr105d/2xfRM4ceGF7trzlNUvYCqR8BZPSwmcPXkBV42EH0vh7NktYOJPwjJ3FP1a5rJfMBAcfNlTFAK1cyTwv+eVxJQRCfhnkQZKfL5XGSU9x6z8ArOveqKAqBhfAAAAAElFTkSuQmCC';
  } @else if ($color == 'black') {
    $selectedColor: black;
    $bgImage: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMTggMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYzLjEgKDkyNDUyKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbnMvaWNfY2hlY2tfYm94IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMy4wMDAwMDAsIC0zLjAwMDAwMCkiIGZpbGw9IiMwMDAwMDAiPgogICAgICAgICAgICA8ZyBpZD0iSWNvbi0yNHB4Ij4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xOSwzIEw1LDMgQzMuODksMyAzLDMuOSAzLDUgTDMsMTkgQzMsMjAuMSAzLjg5LDIxIDUsMjEgTDE5LDIxIEMyMC4xMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSw1IEMyMSwzLjkgMjAuMTEsMyAxOSwzIEwxOSwzIFogTTEwLDE3IEw1LDEyIEw2LjQxLDEwLjU5IEwxMCwxNC4xNyBMMTcuNTksNi41OCBMMTksOCBMMTAsMTcgTDEwLDE3IFoiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4='
  }

  border: 2px solid $selectedColor;

  &:focus {
    outline: 0;
  }

  &[type=checkbox ] {
    background-color: #fff;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  &[type=checkbox]:checked {
    background: #fff url($bgImage) no-repeat -2px -2px;
  }

  &[type=checkbox]:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
