@import "variables";


.footer-top {
  background: color(blue, blue_900);
  border-radius: 0;
  padding: 0;

  .language-switch {
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__img {
      height: 28px;
      width: 28px;
      border-radius: 30px;
      z-index: 1;
      margin-left: .5rem;
    }

    &__drop-down {
      z-index: 1;
    }

    &__caret {
      background-color: transparent !important;
      border-color: transparent !important;
      padding: 0 !important;
      box-shadow: none;

      &:after {
        border-top-color: color(gray, gray_600);
        border-bottom-color: color(gray, gray_600);
        padding-top: 0;
        margin-left: 10px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &__dropdown-menu {
      padding: 0;

      &.show {
        left: -70px !important;
        top: -5px !important;
      }
    }

    &__flag-img {
      height: 28px;
      width: 28px;
      margin-left: 0;
      margin-right: 1rem;
      border-radius: 30px;
      border: 1px solid color(gray, gray_300);
    }

    &__dropdown-item {
      padding-bottom: 7px;
      padding-top: 7px;
      border-bottom: 1px solid color(gray, gray_300);

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .footer-icon {
    width: 28px;
  }

  .footer-bold-text {
    color: color(gray, gray_080);
    font-family: WorkSansSemiBold;
    margin-left: 1rem;
  }

}

.footer-first-row
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item
  {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.footer-last-row
{
  &__icon
  {
    height: 24px;
  }
}


.footer-bottom {
  padding: 0;

  a, p {
    display: inline-flex;
    color: color(blue, blue_main);
    justify-content: center;
    align-items: center;
    margin: {
      right: 15px;
      bottom: 0;
    };
  }
}

/* ====================================
            Media Queries
======================================*/


@media #{$sm} {
  .footer-top {
    .container {
      padding-left: 3rem;
    }
    .footer-first-row
    {
      flex-direction: column;
      align-items: flex-start;
    }
    .footer-last-row {
      flex-direction: column;

      .btn-link {
        padding-left: 0 !important;
        text-align: left;
      }
    }
  }

  .footer-bottom {

    a, p {
      text-align: center;
      font-size: 14px;
      margin-right: 1rem;
      margin-left: 2rem;
    }

    p {
      margin-top: 10px;
    }
  }
}

@media #{$tiny} {
  .footer-bottom {
    a, p {
      margin-right: 0;
      margin-left: 1rem;
    }
  }
}

.sm-mb-3 {
  margin-top: 0.3rem;
}

@media #{$xs} {
  .sm-mt-1 {
    margin-top: 1rem;
  }
  .sm-mb-3 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sm-footer-contact{
    padding: 0;
    display: block !important;
  }
}

@media #{$sm}, #{$md}, #{$lg}, #{$xl} {
 .sm-footer-contact{
   display: none;
 }
}

@media #{$sm} {
  .sm-mb-3 {
    margin-top: 0.5rem;
    margin-bottom: 1.0rem;
  }
 }

.link-bg-white {
  padding: 0 !important;
  background-color: transparent !important;
  color: color(green, green_main);
  border: 0;
  text-decoration: none;

  &.left-arrow:before {
    @include arrowSignPseudo('left', color(green, green_main));
  }

  &.left-arrow:after {
    display: none;
  }

  &.right-arrow:after {
    @include arrowSignPseudo('right', color(green, green_main));
  }

  &.right-arrow:before {
    display: none;
  }

  &:focus {
    @include btnLinkStates('focus');
    box-shadow: none;
  }

  &:active {
    @include btnLinkStates('active');
  }

  &:disabled {
    @include btnLinkStates('disabled');
  }

  &:hover {
    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
    color: #87d4b8 !important;
    border-color: transparent !important; 
  }
}