@import "../variables";

#loading-bar-spinner.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  left: 0;
  top: 50%;
  z-index: 19 !important;
  animation: loading-bar-spinner 1000ms linear infinite;

  .spinner-icon {
    border: solid 5px transparent;
    border-top-color: color(green, green_main) !important;
    border-left-color: color(green, green_main) !important;
    border-radius: 50%;
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
