@media #{$sm} {
  .btn {
    display: block;
    width: 100%;
  }

  .responsive-ccis {
    width: 100% !important;
  }
}

@media #{$xs} {
  .text-center-xs {
    text-align: center;
  }

  .p-2-xs{
    padding-left: 2rem;
  }
}