@import "../variables";

.main-dashboard {
  padding: 5rem 0 2rem 0;
  .main-actions__wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 3rem 0 4.5rem 0;

    .main-action {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.16);
      justify-content: center;
      align-items: center;
      padding: 4rem 0;
      width: 30%;
      color: black;

      &:hover {
        box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25)
      }

      img {
        margin-bottom: 1rem;
      }
    }
  }
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .main-dashboard {
    .main-actions__wrapper {
      flex-direction: column;
      align-items: normal;
      padding: 0;

      .main-action {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}

.on-fido-message__container {
  background-color: #171069;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row-w-90{
  width: 90%;
  margin: auto;
}

@media #{$sm} {
  .main-dashboard {
     padding: 2rem 0 2rem 0;
  }

  .sm-pb-10{
    padding-bottom: 10px;
  }
}

#main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#container {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}
