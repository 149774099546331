input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]:focus {
  outline: none !important; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-runnable-track {
  background: color(blue, blue_900);
}

input[type=range]::-webkit-slider-thumb {
  outline: none;
  -webkit-appearance: none;
  background: color(green, green_main);
  height: 20px;
  width: 20px;
  margin-top: -6px;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb,
input[type=range]::-ms-thumb {
  background: color(green, green_main);
  height: 20px;
  width: 20px;
  cursor: pointer;
}
