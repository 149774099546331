@import '../variables';

.greet__wrapper {
  p {
    font: 200 16px 'WorkSansLight', Helvetica, sans-serif;
    padding: 5px 5px 0 0;
  }

  h3 {
    font: 200 16px 'WorkSansMedium', Helvetica, sans-serif;
    font-weight: bold;
  }

  .greet {
    @extend .font-40;
    text-align: left;
    padding: 30px 7px 7px 0;
  }

  .content {
    padding-left: 5px;
    h3 {
      padding-top: 25px;
      padding-bottom: 10px;
      margin: 0;
      padding: 5px 5px 5px 0;
    }
  }

  .intro {
    padding: 5px 5px 0 0;
  }

  button {
    margin-bottom: 20px;
  }

  .image_area {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    img {
      width: 100%;
      max-width: 500px;
      flex : 1
    }
  }
}
