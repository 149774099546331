@import '../../variables';

@mixin alignPaymentInfo($width) {
  display: flex;
  align-items: center;
  width: $width;
}

.payment-plan__main {
  background: color(gray, gray_200);
  color: color(gray, gray_010);
  border-radius: 5px;
  margin-top: 5px;

  &.completed {
    background-color: color(green, green_400);
    color: black;
  }

  &.current {
    background-color: color(blue, blue_400);
    color: black;
  }

  &.problem {
    background-color: color(red, main);
    color: black;
  }

  &.partially-paid {
    background-color: color(yellow, main);
    color: black;
  }

  &.up-coming {
    background-color: color(gray, gray_200);
    color: color(gray, gray_010);
  }

  &.canceled {
    background-color: color(orange, orange_400);
    color: black;
  }
}

.payment-plan_items {
  padding-top: 10px;

  &__paid{
    padding-left: 45px;
  }
}

.payment-plan__dropdown-button {
  color: white !important;

  &.no-display {
    display: none;
  }

  &.btn.btn-link:focus {
    color: white !important;
  }
}

.payment-plan__dropdown {
  background: color(gray, gray_020);
  padding: 12px 0;

  &.completed {
    margin-top: 2px;
    background-color: color(green, green_300);
  }

  &.current {
    background-color: color(blue, blue_300);
    color: black;
    margin-top: 2px;
  }

  &.problem {
    background-color: color(red, red_300);
    margin-top: 2px;
  }

  &.partially-paid {
    background-color: color(yellow, yellow_300);
    margin-top: 2px;
  }

  &.up-coming {
    background-color: color(gray, gray_300);
    margin-top: 2px;
  }

  &.canceled {
    background-color: color(orange, orange_300);
    margin-top: 2px;
  }
}

.payment-plan__row {
  height: 60px;
}

.payment-plan__dropdown-amount {
  text-align: end;
}

@media #{$lg}, #{$xl} {
  .payment-plan__row {
    height: 40px;
  }
}

@media #{$sm} {
  .payment-plan__row {
    height: 60px;
    font-size: 15px;
  }
}

.payment-plan {
  padding: 78px;
  background-color: color(gray, gray_080);

  &__title {
    margin-bottom: 78px;
  }
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .payment-plan {
    padding: 40px 15px;

    &__title {
      font-size: 25px;
      margin-bottom: 40px;
    }
  }
}

@media #{$lg}, #{$xl}, #{$md} {
  .payment-plan .payment-paid.coming-up {
    display: flex !important;
  }
}
