@import "../../mixins";

.bank {
  .terms-and-conditions__checkbox__color {
    @include checkbox('green');
  }

  .sca__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .sca-item {
      width: calc(16.66% - 2rem);
      height: 109px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: whitesmoke 0 0 30px 20px;
      margin: 1.5rem 1rem 0 1rem;
      cursor: pointer;
      border-radius: 5px;

      &.disabled {
        pointer-events: none;
        opacity: .3;
      }
    }
  }
}

/* ====================================
              Media Queries
======================================*/
@media #{$sm} {
  .bank .sca__wrapper {

    .sca-item {
      width: 38%;
    }
  }
}
