@import "../variables";

.country-input {
  &_search
  {
    position: sticky;
    top:0;
    INPUT[type="text"]
    {
      width: 100%;
      height: 30px;
      font-size: 11px;
    }
  }

  &_tel
  {
    flex-grow: 1;
  }
  .country-input-col {
    display: flex;
    align-items: center;
    background-color: color(gray, gray_100);
    border-radius: 5px;

    .flag__img {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      background-color: white;
      z-index: 1;
    }

    .country__dropdown {

      .flag__caret {
        background-color: transparent !important;
        border-color: transparent !important;
        padding: 0 0 0 1.5rem !important;

        &:after {
          border-top-color: color(gray, gray_600);
          border-bottom-color: color(gray, gray_600);
          padding-top: 0;
          margin-left: 10px;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .dropdown-menu {
        padding: 0;
        height: 200px;
        width: 265px;
        overflow-y: scroll;
        overflow-x: hidden;
        .flag__img {
          margin-left: 0;
          margin-right: 1rem;
          border: 1px solid color(gray, gray_300);
        }
      }

      .dropdown-item {
        padding-bottom: 7px;
        padding-top: 7px;
        border-bottom: 1px solid color(gray, gray_300);

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .country-code {
      z-index: 1;
      font: 20px WorkSansRegular;
      padding-left: 1rem;
      color: color(gray, gray_900);
    }
  }
}

.center__field {
  display: flex;
  align-items: center;
  justify-content: center;
}