@import "../../variables";

.loan-main-info {
  background-color: color(blue, blue_main);
  padding: 4rem;

  .loan-info__title {
    margin-bottom: 12px;
    color: color(blue, blue_400);

    &.small-view {
      display: none;
    }

    &.large_view {
      display: block;
    }
  }

  .loan-left__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    .loan-left__number {
      margin-bottom: 1rem;
      letter-spacing: 3px;
    }
  }

  .loan-progress-bar__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .loan-progress-bar {
      background-color: color(blue, blue_700);
      position: relative;
      padding: 1rem;
      width: 100%;
      border-radius: 20px;

      .filled {
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 20px;
      }
    }
  }

  .loan-size-date {
    display: flex;
    margin-top: 2rem;

    .loan-info {

      &__value {
        font-family: "League Spartan", Futura, Arial, sans-serif;
        font-size: 25px;
        color: white;
      }
    }
  }
}

/* ====================================
            Media Queries
======================================*/
@media #{$sm} {
  .loan-main-info {
    padding: 2rem 1rem;

    .loan-left__wrapper .loan-left__number {
      font-size: 30px;
      line-height: 30px;
    }

    .loan-size-date {
      .loan-info {
        padding: 0;
        text-align: left;

        .loan-info__title {
          &.small-view {
            display: block;
          }

          &.large-view {
            display: none;
          }
        }

        &:nth-child(2) {
          display: none;
        }

        &__value {
          font-size: 18px;
        }
      }
    }
  }
}

.pay-loan {
  color: white;
  padding-bottom: 0.8rem;
}

.main-heading{
  padding-top: 4.8rem;
}

@media #{$sm}{
  .main-heading{
    padding-top: 1.8rem;
  }
}

.bg-grey{
  background: #f0f0f0;
}


@media #{$md}, #{$lg}, #{$xl} {
  .loan-interest-rate{
    padding: 0rem 10rem 3rem 10rem !important;
  }
}

.loan-interest-rate{
  padding: 0rem 0rem 3rem 0rem;
}

