.due-payment {
  background-color: #ff7b84;
  line-height: 1.2;
  height: 15em;
}


.debt-collection {
  background-color: #ff7b84;
  line-height: 1.2;
  height: 17em;
}

@media #{$sm} {
  .debt-collection {
    height: 20em;
  }
}

@media #{$xs} {
  .debt-collection {
    height: 26em;
  }
}