@import "fontFace";
@import "variables";
@import "typography.scss";
@import "buttons";
@import "range";
@import "steps";
@import "navbar";
@import "footer";
@import "investor";
@import "investorOnboarding/aml";
@import "investorOnboarding/signContract";
@import "investorOnboarding/walkThruInvestor.scss";
@import "investorOnboarding/contract";
@import "mobile";
@import "borrower/borrower";
@import "shared/index";
@import "borrowerOnboarding/borrowerOnBoarding";
@import "modals/termsModal";
@import "modals/withdrawModal";
@import "login/login";
@import "profile/profile";
@import "./dashboard/dashboard";

/* ====================================
             Global Styles
======================================*/
.error {
  color: red;
}

.form-error-message {
  position: absolute;
  top: 4rem;
  color: red;
  left: 1rem;
}

.left-35 {
  left: 35%;
}

.button-group {
  .btn {
    margin: 0 15px 0 0;
  }
}

button:focus {
  outline: 0;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

section {
  padding: 5em;

  &.full-width {
    padding: 2em 0;

    .row {
      padding: 2em 15%;
      max-width: 100%;
      overflow: hidden;
    }
  }
}

.text-inline-container {
  width: 300px;
  height: 70px;
  margin: 0 auto;

  span {
    vertical-align: middle;
    font-size: 1.2em;
  }

  .large {
    font-size: 3em;
  }
}

.padding-y-10 {
  padding: 10rem 0;
}

/* ====================================
                 Links
======================================*/
a {
  color: color(green, green_main);
  font-size: 16px;

  &:focus {
    color: color(green, green_400);
    outline: 0;
  }

  &:disabled {
    color: color(green, green_600);
  }

  &:active {
    color: color(gray, gray_400);
  }

  &:hover {
    color: #424ce0;
    text-decoration: none;
  }
}

/* ====================================
              Text Input
=================
=====================*/
INPUT[type=text],
INPUT[type=date],
INPUT[type=tel],
INPUT[type=email],
INPUT[type=password] {
  background-color: color(gray, gray_100);
  height: 60px;
  border: 0;
  border-radius: 5px;
  font-family: WorkSansRegular, sans-serif;
  font-size: 20px;
  color: black;
  padding-left: 1.5rem;

  &:focus {
    background-color: color(gray, gray_100);
  }

  &::placeholder {
    color: color(gray, gray_500);
  }
}

INPUT[type=number] {
  &::placeholder {
    color: color(gray, gray_500);
  }
}

.date-of-birth small{
  padding-top: 25px;
}